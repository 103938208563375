import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  MuiThemeProvider,
  createMuiTheme,
  FormControl,
  InputLabel,
  AppBar,
  Slider,
  Tabs,
  Tab,
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteStrategy,
  getAllCoins,
  getStrategyById,
  postStrategy,
  updateStrategy,
} from "../../../../api/strategy";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

function getEmptyStrategy() {
  return {
    fullName: "",
    description: "",
    bot: "",
    coins: {},
    longActive: true,
    shortActive: true,
    //CONFIGURATION
    windowTime: {
      active: true,
      long: 0,
      short: 0,
    },
    minVolume: {
      active: true,
      long: 0,
      short: 0,
    },
    rsi: {
      active: true,
      long: 0,
      short: 0,
    },
    maxpercVar1h: {
      active: true,
      long: [1, 20],
      short: [1, 20],
    },
    maxpercVar30m: {
      active: true,
      long: [1, 20],
      short: [1, 20],
    },
    maxpercVar10m: {
      active: true,
      long: [1, 20],
      short: [1, 20],
    },
    bollingerBands: {
      active: true,
      long: 0,
      short: 0,
    },
    stopLoss1: 0,
    stopLoss2: 0,
    takeProfit1: 0,
    takeProfit2: 0,
    active: true,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function EditStrategiesPage() {
  const isMounted = useRef(true);
  const [strategy, setStrategy] = useState(getEmptyStrategy());
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [allCoins, setAllCoins] = useState([]);
  const [selectedCoins, setSelectedCoins] = useState([]);
  const [tab, setTab] = useState(0);
  const strategyId = useParams().id;
  const history = useHistory();
  const bots = ["Slope", "Scalping", "Swing"];

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    if (!strategyId) {
      disableLoadingData();
      return;
    }
    getStrategyById(strategyId)
      .then((res) => {
        if (res.status === 200) {
          const strategy = res.data;
          setStrategy(strategy);
          setSelectedCoins(strategy.coins);
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({ error: error, customMessage: "Could not get strategy." });
        history.push("/strategies");
      });
  }, [strategyId, disableLoadingData, history]);

  useEffect(() => {
    if (strategy !== undefined) {
      getAllCoins()
        .then((res) => {
          if (res.status === 200 && isMounted.current) {
            setAllCoins(res.data);
            if (strategy.coins.length === 0 && !strategyId) {
              const initialSelection = res.data.reduce((acc, coin) => {
                acc[coin] = true;
                return acc;
              }, {});

              setSelectedCoins(initialSelection);
            }
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not get coins.",
          });
        });
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  function saveStrategy() {
    let saveStrategy = strategy;
    saveStrategy.coins = selectedCoins;
    if (!strategyId) {
      postStrategy(saveStrategy)
        .then((res) => {
          if (res.status === 201) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Strategy successfully created.",
            });
            history.push("/strategies");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save strategy.",
          });
        });
    } else {
      updateStrategy(strategyId, saveStrategy)
        .then((res) => {
          if (res.status === 200) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Changes successfully saved.",
            });
            history.push("/strategies");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save changes.",
          });
        });
    }
  }

  const handleChange = (element, subElement) => (event, newValue) => {
    if (subElement) {
      setStrategy((prevStrategy) => ({
        ...prevStrategy,
        [element]: {
          ...prevStrategy[element],
          [subElement]: event !== undefined ? event : newValue,
        },
      }));
    } else {
      setStrategy((prevStrategy) => ({
        ...prevStrategy,
        [element]: event !== undefined ? event : newValue,
      }));
    }
  };

  const handleCheckboxChange = (coin) => {
    setSelectedCoins([
      {
        ...selectedCoins[0],
        [coin]: !selectedCoins[0][coin],
      },
    ]);
  };

  const handleSelectAll = (event) => {
    const newSelectedCoins = [
      allCoins.reduce((acc, coin) => {
        acc[coin] = event.target.checked;
        return acc;
      }, {}),
    ];
    console.log("newSelectedCoins: ", newSelectedCoins);
    setSelectedCoins(newSelectedCoins);
  };

  const bollingerBandOptions = ["BUY", "NEUTRAL", "BOUGHT"];

  const valueLabelFormat = (value) => {
    return bollingerBandOptions[value];
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    // console.log("Updated_Strategy: ", strategy);
  }, [strategy]);

  useEffect(() => {
    // console.log("SELECTED_COINS: ", selectedCoins);
  }, [selectedCoins]);

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Edit strategy"></CardHeader>
          <CardBody>
            <TextField
              id={`fullName`}
              label="Full name"
              value={strategy?.fullName}
              onChange={(event) => {
                handleChange("fullName")(event.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <br />
            <TextField
              id={`description`}
              label="Description"
              value={strategy?.description}
              onChange={(event) => {
                handleChange("description")(event.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <br />
            <FormControl variant="outlined" style={{ minWidth: 120 }}>
              <InputLabel id="strategies-label">Bot</InputLabel>
              <Select
                labelId="strategies-label"
                id="strategies-select"
                value={strategy?.bot}
                onChange={(event) => {
                  handleChange("bot")(event.target.value);
                }}
              >
                {bots.map((bot) => (
                  <MenuItem key={bot} value={bot}>
                    {bot}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                id={`takeProfit1`}
                label="Take Profit 1"
                value={strategy?.takeProfit1}
                onChange={(event) => {
                  handleChange("takeProfit1")(event.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                variant="outlined"
                required
              />
              <TextField
                id={`takeProfit2`}
                label="Take Profit 2"
                value={strategy?.takeProfit2}
                onChange={(event) => {
                  handleChange("takeProfit2")(event.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                variant="outlined"
                required
              />
              <TextField
                id={`stopLoss1`}
                label="Stop Loss 1"
                value={strategy?.stopLoss1}
                onChange={(event) => {
                  handleChange("stopLoss1")(event.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                variant="outlined"
                required
              />
              <TextField
                id={`stopLoss2`}
                label="Stop Loss 2"
                value={strategy?.stopLoss2}
                onChange={(event) => {
                  handleChange("stopLoss2")(event.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                variant="outlined"
                required
              />
            </FormControl>
            {/* MULTI-TAB */}
            <br />
            <br />
            <AppBar
              position="static"
              style={{ backgroundColor: "#c378c4", color: "white" }}
            >
              <Tabs
                value={tab}
                onChange={handleChangeTab}
                aria-label="simple tabs example"
              >
                <Tab label="Longs Config" {...a11yProps(0)} />
                <Tab label="Shorts Config" {...a11yProps(1)} />
                <Tab label="Coins" {...a11yProps(2)} />
              </Tabs>
            </AppBar>
            <TabPanel value={tab} index={0}>
              {/* Longs Config Form Elements */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={strategy?.longActive || false}
                    onChange={(event) =>
                      handleChange("longActive")(event.target.checked)
                    }
                    name="longsActivated"
                  />
                }
                label="Longs activados"
              />
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="d-flex align-items-center mb-3">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              (strategy?.longActive &&
                                strategy?.windowTime.active) ||
                              false
                            }
                            onChange={(event) =>
                              handleChange(
                                "windowTime",
                                "active"
                              )(event.target.checked)
                            }
                            name="windowTime"
                          />
                        }
                        label="Ventana de tiempo"
                      />
                      <Slider
                        value={strategy.windowTime.long}
                        onChange={(e, newValue) =>
                          handleChange("windowTime", "long")(newValue)
                        }
                        valueLabelDisplay="auto"
                        min={1}
                        max={60}
                        marks={[
                          { value: 1, label: "1min" },
                          { value: 60, label: "60min" },
                        ]}
                        disabled={
                          !strategy?.longActive || !strategy.windowTime.active
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex align-items-center mb-3">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              (strategy?.longActive &&
                                strategy?.minVolume.active) ||
                              false
                            }
                            onChange={(event) =>
                              handleChange(
                                "minVolume",
                                "active"
                              )(event.target.checked)
                            }
                            name="minVolume"
                          />
                        }
                        label="Volum. mínimo en millones de usdt"
                      />
                      <Slider
                        value={strategy.minVolume.long}
                        onChange={(e, newValue) =>
                          handleChange("minVolume", "long")(newValue)
                        }
                        valueLabelDisplay="auto"
                        min={0}
                        max={500}
                        marks={[
                          { value: 0, label: "0M" },
                          { value: 500, label: "500M" },
                        ]}
                        disabled={
                          !strategy?.longActive || !strategy.minVolume.active
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex align-items-center mb-3">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              (strategy?.longActive && strategy?.rsi.active) ||
                              false
                            }
                            onChange={(event) =>
                              handleChange(
                                "rsi",
                                "active"
                              )(event.target.checked)
                            }
                            name="rsi"
                          />
                        }
                        label="RSI inferior o igual a"
                      />
                      <Slider
                        value={strategy.rsi.long}
                        onChange={(e, newValue) =>
                          handleChange("rsi", "long")(newValue)
                        }
                        valueLabelDisplay="auto"
                        min={0}
                        max={100}
                        marks={[
                          { value: 0, label: "0" },
                          { value: 100, label: "100" },
                        ]}
                        disabled={!strategy?.longActive || !strategy.rsi.active}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex align-items-center mb-3">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              (strategy?.longActive &&
                                strategy?.maxpercVar1h.active) ||
                              false
                            }
                            onChange={(event) =>
                              handleChange(
                                "maxpercVar1h",
                                "active"
                              )(event.target.checked)
                            }
                            name="maxpercVar1h"
                          />
                        }
                        label="%Variación ult.hora"
                      />
                      <Slider
                        value={strategy.maxpercVar1h.long}
                        onChange={(e, newValue) =>
                          handleChange("maxpercVar1h", "long")(newValue)
                        }
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        min={1}
                        max={20}
                        marks={[
                          { value: 1, label: "1%" },
                          { value: 20, label: "20%" },
                        ]}
                        disabled={
                          !strategy?.longActive || !strategy.maxpercVar1h.active
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex align-items-center mb-3">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              (strategy?.longActive &&
                                strategy?.maxpercVar30m.active) ||
                              false
                            }
                            onChange={(event) =>
                              handleChange(
                                "maxpercVar30m",
                                "active"
                              )(event.target.checked)
                            }
                            name="maxpercVar30m"
                          />
                        }
                        label="%Variación ult.30min"
                      />
                      <Slider
                        value={strategy.maxpercVar30m.long}
                        onChange={(e, newValue) =>
                          handleChange("maxpercVar30m", "long")(newValue)
                        }
                        valueLabelDisplay="auto"
                        min={1}
                        max={20}
                        marks={[
                          { value: 1, label: "1%" },
                          { value: 20, label: "20%" },
                        ]}
                        disabled={
                          !strategy?.longActive ||
                          !strategy.maxpercVar30m.active
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex align-items-center mb-3">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              (strategy?.longActive &&
                                strategy?.maxpercVar10m.active) ||
                              false
                            }
                            onChange={(event) =>
                              handleChange(
                                "maxpercVar10m",
                                "active"
                              )(event.target.checked)
                            }
                            name="maxpercVar10m"
                          />
                        }
                        label="%Variación ult.10min"
                      />
                      <Slider
                        value={strategy.maxpercVar10m.long}
                        onChange={(e, newValue) =>
                          handleChange("maxpercVar10m", "long")(newValue)
                        }
                        valueLabelDisplay="auto"
                        min={1}
                        max={20}
                        marks={[
                          { value: 1, label: "1%" },
                          { value: 20, label: "20%" },
                        ]}
                        disabled={
                          !strategy?.longActive ||
                          !strategy.maxpercVar10m.active
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex align-items-center mb-3">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              (strategy?.longActive &&
                                strategy?.bollingerBands.active) ||
                              false
                            }
                            onChange={(event) =>
                              handleChange(
                                "bollingerBands",
                                "active"
                              )(event.target.checked)
                            }
                            name="bollingerBands"
                          />
                        }
                        label="Bandas de Bollinger"
                      />
                      <Slider
                        value={strategy.bollingerBands.long}
                        onChange={(e, newValue) =>
                          handleChange("bollingerBands", "long")(newValue)
                        }
                        valueLabelFormat={valueLabelFormat}
                        valueLabelDisplay="auto"
                        step={1}
                        marks
                        min={0}
                        max={2}
                        disabled={
                          !strategy?.longActive ||
                          !strategy.bollingerBands.active
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              {/* Shorts Config Form Elements */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={strategy?.shortActive || false}
                    onChange={(event) =>
                      handleChange("shortActive")(event.target.checked)
                    }
                    name="shortsActivated"
                  />
                }
                label="Shorts activados"
              />
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="d-flex align-items-center mb-3">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              (strategy?.shortActive &&
                                strategy?.windowTime.active) ||
                              false
                            }
                            onChange={(event) =>
                              handleChange(
                                "windowTime",
                                "active"
                              )(event.target.checked)
                            }
                            name="windowTime"
                          />
                        }
                        label="Ventana de tiempo"
                      />
                      <Slider
                        value={strategy.windowTime.short}
                        onChange={(e, newValue) =>
                          handleChange("windowTime", "short")(newValue)
                        }
                        valueLabelDisplay="auto"
                        min={1}
                        max={60}
                        marks={[
                          { value: 1, label: "1min" },
                          { value: 60, label: "60min" },
                        ]}
                        disabled={
                          !strategy?.shortActive || !strategy.windowTime.active
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex align-items-center mb-3">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              (strategy?.shortActive &&
                                strategy?.minVolume.active) ||
                              false
                            }
                            onChange={(event) =>
                              handleChange(
                                "minVolume",
                                "active"
                              )(event.target.checked)
                            }
                            name="minVolume"
                          />
                        }
                        label="Volum. mínimo en millones de usdt"
                      />
                      <Slider
                        value={strategy.minVolume.short}
                        onChange={(e, newValue) =>
                          handleChange("minVolume", "short")(newValue)
                        }
                        valueLabelDisplay="auto"
                        min={0}
                        max={500}
                        marks={[
                          { value: 0, label: "0M" },
                          { value: 500, label: "500M" },
                        ]}
                        disabled={
                          !strategy?.shortActive || !strategy.minVolume.active
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex align-items-center mb-3">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              (strategy?.shortActive && strategy?.rsi.active) ||
                              false
                            }
                            onChange={(event) =>
                              handleChange(
                                "rsi",
                                "active"
                              )(event.target.checked)
                            }
                            name="rsi"
                          />
                        }
                        label="RSI superior o igual a"
                      />
                      <Slider
                        value={strategy.rsi.short}
                        onChange={(e, newValue) =>
                          handleChange("rsi", "short")(newValue)
                        }
                        valueLabelDisplay="auto"
                        min={0}
                        max={100}
                        marks={[
                          { value: 0, label: "0" },
                          { value: 100, label: "100" },
                        ]}
                        disabled={
                          !strategy?.shortActive || !strategy.rsi.active
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex align-items-center mb-3">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              (strategy?.shortActive &&
                                strategy?.maxpercVar1h.active) ||
                              false
                            }
                            onChange={(event) =>
                              handleChange(
                                "maxpercVar1h",
                                "active"
                              )(event.target.checked)
                            }
                            name="maxpercVar1h"
                          />
                        }
                        label="%Variación ult.hora"
                      />
                      <Slider
                        value={strategy.maxpercVar1h.short}
                        onChange={(e, newValue) =>
                          handleChange("maxpercVar1h", "short")(newValue)
                        }
                        valueLabelDisplay="auto"
                        min={1}
                        max={20}
                        marks={[
                          { value: 1, label: "1%" },
                          { value: 20, label: "20%" },
                        ]}
                        disabled={
                          !strategy?.shortActive ||
                          !strategy.maxpercVar1h.active
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex align-items-center mb-3">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              (strategy?.shortActive &&
                                strategy?.maxpercVar30m.active) ||
                              false
                            }
                            onChange={(event) =>
                              handleChange(
                                "maxpercVar30m",
                                "active"
                              )(event.target.checked)
                            }
                            name="maxpercVar30m"
                          />
                        }
                        label="%Variación ult.30min"
                      />
                      <Slider
                        value={strategy.maxpercVar30m.short}
                        onChange={(e, newValue) =>
                          handleChange("maxpercVar30m", "short")(newValue)
                        }
                        valueLabelDisplay="auto"
                        min={1}
                        max={20}
                        marks={[
                          { value: 1, label: "1%" },
                          { value: 20, label: "20%" },
                        ]}
                        disabled={
                          !strategy?.shortActive ||
                          !strategy.maxpercVar30m.active
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex align-items-center mb-3">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              (strategy?.shortActive &&
                                strategy?.maxpercVar10m.active) ||
                              false
                            }
                            onChange={(event) =>
                              handleChange(
                                "maxpercVar10m",
                                "active"
                              )(event.target.checked)
                            }
                            name="maxpercVar10m"
                          />
                        }
                        label="%Variación ult.10min"
                      />
                      <Slider
                        value={strategy.maxpercVar10m.short}
                        onChange={(e, newValue) =>
                          handleChange("maxpercVar10m", "short")(newValue)
                        }
                        valueLabelDisplay="auto"
                        min={1}
                        max={20}
                        marks={[
                          { value: 1, label: "1%" },
                          { value: 20, label: "20%" },
                        ]}
                        disabled={
                          !strategy?.shortActive ||
                          !strategy.maxpercVar10m.active
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex align-items-center mb-3">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              (strategy?.shortActive &&
                                strategy?.bollingerBands.active) ||
                              false
                            }
                            onChange={(event) =>
                              handleChange(
                                "bollingerBands",
                                "active"
                              )(event.target.checked)
                            }
                            name="bollingerBands"
                          />
                        }
                        label="Bandas de Bollinger"
                      />
                      <Slider
                        value={strategy.bollingerBands.short}
                        onChange={(e, newValue) =>
                          handleChange("bollingerBands", "short")(newValue)
                        }
                        valueLabelFormat={valueLabelFormat}
                        valueLabelDisplay="auto"
                        step={1}
                        marks
                        min={0}
                        max={2}
                        disabled={
                          !strategy?.shortActive ||
                          !strategy.bollingerBands.active
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleSelectAll}
                    name="selectAll"
                    defaultChecked={false}
                  />
                }
                label="Select All"
              />
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: "10px",
                }}
              >
                {allCoins.map((coin, index) => (
                  <div
                    key={coin}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedCoins?.[0]?.[coin] || false}
                          onChange={() => handleCheckboxChange(coin)}
                          name={coin}
                          color="primary"
                        />
                      }
                      label={coin}
                    />
                  </div>
                ))}
              </div>
            </TabPanel>

            <FormControlLabel
              control={
                <Checkbox
                  checked={strategy.active}
                  onChange={() =>
                    setStrategy({ ...strategy, active: !strategy.active })
                  }
                  name="checkActive"
                />
              }
              label="Active"
            />
          </CardBody>
        </Card>
        <Button
          onClick={() => history.push("/strategies")}
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          Back
        </Button>
        <Button
          onClick={() => saveStrategy()}
          variant="outlined"
          color="primary"
          style={{ marginRight: "20px" }}
        >
          Save strategy
        </Button>
        {strategyId && (
          <>
            <MuiThemeProvider theme={theme}>
              <Button
                onClick={() => setOpenConfirmDialog(true)}
                variant="outlined"
                color="secondary"
              >
                Delete strategy
              </Button>
            </MuiThemeProvider>

            <ConfirmDialog
              title={"Are you sure you want to delete this strategy?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deleteStrategy(strategyId)
                  .then((res) => {
                    if (res.status === 204 || res.status === 200) {
                      alertSuccess({
                        title: "Deleted!",
                        customMessage: "Strategy deleted successfully",
                      });
                      history.push("/strategies");
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "Could not delete strategy.",
                    });
                  });
              }}
            />
          </>
        )}
      </>
    );
}
